import keyMirror from "keymirror";
import difference from "lodash/difference";
import {
  WALLET_PASS,
  BADGES,
  CONFIRMATION_EMAIL,
  DOCUMENTS,
  ENGAGEMENT_SCORING,
  EXHIBITOR_INVITATIONS,
  INVOICING,
  MODERATION,
  MULTILINGUAL,
  PAYMENT,
  PROMO_CODES,
  REGISTRATION_FORM,
  SEATSIO,
  BLOG,
  SCHEDULE,
  EXHIBITOR_MEETINGS,
  SCHEDULED_1TO1_MEETING
} from "./FeaturesSet";

export const ActionTypes = keyMirror({
  DEV_NULL: null,
  SET_REGISTERED_AT_DATES: null,
  CLEAR_FILTERS: null,
  CLEAR_SELECTED_GUESTS: null,
  START_FETCHING_GUESTS: null,
  CLEAR_STATISTICS: null,
  EXPORT_BADGES: null,
  EXPORT_GUEST_LIST: null,
  EXPORT_QR_CODES: null,
  RECEIVE_ACCOUNT_SUCCESS: null,
  FETCH_ACCOUNT_REQUEST: null,
  UPDATE_ACCOUNT_SUCCESS: null,
  UPDATE_ACCOUNT_FAILURE: null,
  FETCH_POPULATION_TYPES_SUCCESS: null,
  RECEIVE_EVENT: null,
  FETCH_EVENT_REQUEST: null,
  FETCH_EVENT_GUEST_FIELD_MOST_USED_VALUES_SUCCESS: null,
  RECEIVE_EXPORTS: null,
  RECEIVE_FILTER_ITEMS: null,
  FETCH_GUEST_CATEGORIES: null,
  RECEIVE_GUEST_CATEGORIES_REQUEST: null,
  RECEIVE_GUEST_CATEGORIES: null,
  RECEIVE_GUEST_CATEGORIES_FAILURE: null,
  RECEIVE_GUEST_SORT_OPTIONS: null,
  RECEIVE_GUESTS: null,
  RECEIVE_GUESTS_ERROR: null,
  FETCH_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS: null,
  CREATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS: null,
  CREATE_GUEST_CATEGORY_ACCESSPOINT_FAILURE: null,
  UPDATE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS: null,
  DELETE_GUEST_CATEGORY_ACCESSPOINT_SUCCESS: null,
  FETCH_SAVED_SEARCH_SUCCESS: null,
  FETCH_SAVED_SEARCH_FAILURE: null,
  CURRENT_SAVED_SEARCH_SUCCESS: null,
  CURRENT_SAVED_SEARCH_REQUEST: null,
  CURRENT_SAVED_SEARCH_FAILURE: null,
  CHANGE_CURRENT_SAVED_SEARCH: null,
  CLEAR_CURRENT_SAVED_SEARCH: null,
  CREATE_SAVED_SEARCH_SUCCESS: null,
  CREATE_SAVED_SEARCH_FAILURE: null,
  UPDATE_SAVED_SEARCH_SUCCESS: null,
  UPDATE_SAVED_SEARCH_FAILURE: null,
  CREATE_SAVED_SEARCH_CLEAR: null,
  SAVED_SEARCH_SUCCESS: null,
  SEARCH_GUEST_FIELD_MOST_USED_VALUES_SUCCESS: null,
  RECEIVE_SHOWED_UP_STATUSES: null,
  RECEIVE_USER: null,
  DELETE_SAVED_SEARCH_SUCCESS: null,
  DELETE_SAVED_SEARCH_FAILURE: null,
  SET_CHECKED_IN_AT_ACCESSPOINT_IDS: null,
  SET_ACCEPTED_AT_ACCESSPOINT_IDS: null,
  SET_REJECTED_AT_ACCESSPOINT_IDS: null,
  SET_EXPECTED_AT_ACCESSPOINT_IDS: null,
  SET_FILLING_FILTER_STATE: null,
  SET_GUEST_PAGE: null,
  SET_HYDRATATION_STATE: null,
  SET_SELECTED_GUEST_CATEGORY_IDS: null,
  SET_SELECTED_GUEST_SORT_OPTIONS: null,
  SET_SELECTED_GUEST_STATUSES: null,
  SET_SELECTED_SALESFORCE_SYNC_STATUSES: null,
  SET_SELECTED_SSO_STATUSES: null,
  SET_SELECTED_PAYMENT_STATUSES: null,
  SET_SELECTED_SHOWED_UP_STATUSES: null,
  SET_SELECTED_GUEST_LABEL_IDS: null,
  SET_SELECTED_EMAIL_ACTIVITY: null,
  SET_SELECTED_THEMATIC_IDS: null,
  SET_SELECTED_OPTINS: null,
  SET_SELECTED_ENGAGEMENT_LEVELS: null,
  SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_THEMATICS_IDS: null,
  SET_IN_GUESTS_THEMATICS_QUARTILES_SELECTED_QUARTILES_IDS: null,
  SET_SIMPLE_SEARCH_QUERY: null,
  SHOW_NOTICE: null,
  SORT_SAVED_SEARCHES: null,
  TOGGLE_BUILDER_EDIT_MODE: null,
  TOGGLE_FILTER_ITEM_STATUS: null,
  TOGGLE_GUEST: null,
  TOGGLE_GUEST_CATEGORIES: null,
  UPDATE_GUEST_COUNT: null,
  UPDATE_SHEETS: null,
  UPDATE_UI: null,
  UPDATE_UI_ERROR: null,
  FETCH_LEADS_EXPORT_EMAIL_SUCCESS: null,
  FETCH_VISIT_ROUTE_DIGEST_EMAIL_SUCCESS: null,
  FETCH_VISIT_ROUTE_EXPORT_EMAIL_SUCCESS: null,
  UPDATE_VISIT_ROUTE_SUCCESS: null,
  CLEAR_LEADS_EXPORT_EMAIL: null,
  EVENT_ADD_OFFLINE_ERRORS: null,
  RELOAD_GUESTS_PAGE: null,
  UPDATE_EVENT_LAST_USED_COLORS_SUCCESS: null,

  // duplication_operation
  FETCH_DUPLICATION_OPERATION_REQUEST: null,
  FETCH_DUPLICATION_OPERATION_SUCCESS: null,
  FETCH_DUPLICATION_OPERATION_ERROR: null,

  // preconfigured events
  FETCH_PRECONFIGURED_EVENTS_REQUEST: null,
  FETCH_PRECONFIGURED_EVENTS_SUCCESS: null,
  FETCH_PRECONFIGURED_EVENTS_ERROR: null,

  FETCH_CUSTOM_PRECONFIGURED_EVENTS_REQUEST: null,
  FETCH_CUSTOM_PRECONFIGURED_EVENTS_SUCCESS: null,
  FETCH_CUSTOM_PRECONFIGURED_EVENTS_ERROR: null,

  // guest_fields
  GUEST_FIELD_UPDATE_SUCCESS: null,
  GUEST_FIELD_UPDATE_FAILURE: null,
  GUEST_FIELD_CREATE_SUCCESS: null,
  GUEST_FIELD_CREATE_FAILURE: null,
  RECEIVE_GUEST_FIELD: null,
  FETCH_GUEST_FIELDS_SUCCESS: null,
  FETCH_GUEST_FIELDS_TYPES_SUCCESS: null,

  // guest campaign
  CREATE_GUEST_CAMPAIGN_REQUEST: null,
  CREATE_GUEST_CAMPAIGN_SUCCESS: null,
  CREATE_GUEST_CAMPAIGN_FAILURE: null,
  UPDATE_GUEST_CAMPAIGN_SUCCESS: null,
  UPDATE_GUEST_CAMPAIGN_REQUEST: null,
  UPDATE_GUEST_CAMPAIGN_FAILURE: null,
  RECEIVE_GUEST_CAMPAIGN_SUCCESS: null,
  RECEIVE_GUEST_CAMPAIGN_FAILURE: null,
  GUEST_CAMPAIGNS_SUCCESS: null,
  GUEST_CAMPAIGNS_FAILURE: null,
  GUEST_CAMPAIGN_DUPLICATE_SUCCESS: null,
  GUEST_CAMPAIGN_DUPLICATE_FAILURE: null,
  GUEST_CAMPAIGNS_GLOBAL_COUNTERS_SUCCESS: null,
  CLEAR_GUEST_CAMPAIGNS_STORE: null,
  DELIVER_CAMPAIGN_REQUEST: null,
  DELIVER_CAMPAIGN_SUCCESS: null,
  DELIVER_CAMPAIGN_FAILURE: null,
  CLEAR_GUEST_CAMPAIGN_STORE: null,
  GUEST_CAMPAIGN_DELETE_SUCCESS: null,
  RECEIVER_ACTIVITIES_SUCCESS: null,
  SEND_TEST_EMAIL_SUCCESS: null,
  SEND_TEST_EMAIL_FAILURE: null,

  //event request
  CREATE_EVENT_REQUEST: null,
  CREATE_EVENT_SUCCESS: null,
  CREATE_EVENT_FAILURE: null,
  FETCH_EVENTS_REQUEST: null,
  FETCH_EVENTS_SUCCESS: null,
  FETCH_GUEST_CATEGORIES_FROM_EVENT_SUCCESS: null,
  UPDATE_EVENT_REQUEST: null,
  UPDATE_EVENT_SUCCESS: null,
  UPDATE_EVENT_FAILURE: null,
  CREATE_FROM_EVENT_REQUEST: null,
  CREATE_FROM_EVENT_SUCCESS: null,
  CREATE_FROM_EVENT_FAILURE: null,

  // HistoryItems request
  FETCH_HISTORY_ITEMS_REQUEST: null,
  FETCH_HISTORY_ITEMS_SUCCESS: null,
  FETCH_HISTORY_ITEMS_FAILURE: null,
  CLEAR_HISTORY_ITEMS: null,

  // contact imports errors
  FETCH_EVENT_CONTACT_IMPORT_ERRORS_SUCCESS: null,

  // label request
  RECEIVE_LABELS_SUCCESS: null,
  LABELS_STATS_SUCCESS: null,
  ADD_LABEL_REQUEST: null,
  ADD_LABEL_SUCCESS: null,
  LABEL_FAILURE: null,
  UPDATE_LABEL_REQUEST: null,
  UPDATE_LABEL_SUCCESS: null,
  REMOVE_LABEL_SUCCESS: null,
  UPDATE_GUESTS_LABELS: null,
  LABELS_STATS_CLEAR: null,

  // thematics
  RECEIVE_THEMATICS_REQUEST: null,
  RECEIVE_THEMATICS_SUCCESS: null,
  CREATE_THEMATIC_REQUEST: null,
  CREATE_THEMATIC_SUCCESS: null,
  UPDATE_THEMATIC_REQUEST: null,
  UPDATE_THEMATIC_SUCCESS: null,
  REMOVE_THEMATIC_SUCCESS: null,
  THEMATIC_FAILURE: null,
  CLEAR_THEMATIC_ERRORS: null,

  //session types
  RECEIVE_SESSION_TYPES_REQUEST: null,
  RECEIVE_SESSION_TYPES_SUCCESS: null,
  CREATE_SESSION_TYPE_REQUEST: null,
  CREATE_SESSION_TYPE_SUCCESS: null,
  SESSION_TYPE_FAILURE: null,
  REMOVE_SESSION_TYPE_SUCCESS: null,
  UPDATE_SESSION_TYPE_SUCCESS: null,
  UPDATE_SESSION_TYPE_REQUEST: null,
  CLEAR_SESSION_TYPE_ERRORS: null,

  // webhooks
  RESEND_WEBHOOKS_REQUEST: null,
  RESEND_WEBHOOKS_SUCCESS: null,
  RESEND_WEBHOOKS_FAILURE: null,

  // website page request
  RECEIVE_WEBSITE_PAGES_SUCCESS: null,
  RECEIVE_WEBSITE_PAGES_FAILURE: null,
  RECEIVE_WEBSITE_PAGE_SUCCESS: null,
  RECEIVE_WEBSITE_PAGE_FAILURE: null,
  CREATE_WEBSITE_PAGE_SUCCESS: null,
  UPDATE_WEBSITE_PAGE_SUCCESS: null,
  DESTROY_WEBSITE_PAGE_SUCCESS: null,
  DUPLICATE_WEBSITE_PAGE_SUCCESS: null,
  WEBSITE_PAGE_FAILURE: null,
  RECEIVE_WEBSITE_SUCCESS: null,
  RECEIVE_WEBSITE_FAILURE: null,
  RECEIVE_SECTION_TYPES_SUCCESS: null,
  RECEIVE_SECTION_TYPES_FAILURE: null,
  RECEIVE_WEBSITE_PAGE_TEMPLATES_SUCCESS: null,
  RECEIVE_WEBSITE_PAGE_TEMPLATES_FAILURE: null,
  RECEIVE_WEBSITE_LAYOUTS_SUCCESS: null,
  RECEIVE_WEBSITE_LAYOUTS_FAILURE: null,
  UPDATE_PAGE_SECTIONS: null,
  UPDATE_WEBSITE_REQUEST: null,
  UPDATE_WEBSITE_SUCCESS: null,
  UPDATE_WEBSITE_ERROR: null,
  DISABLE_WEBSITE_SUCCESS: null,
  DISABLE_WEBSITE_ERROR: null,
  RECEIVE_WEBSITE_CHANGES: null,
  UPDATE_SECTION: null,
  UPDATE_APPEARANCE: null,
  CREATE_NEW_SECTION: null,
  CREATE_NEW_BLOCK: null,
  REMOVE_SECTION: null,
  REMOVE_BLOCK: null,
  ADD_PAGE_TO_SETTINGS: null,
  REMOVE_PAGE_FROM_SETTINGS: null,
  UPDATE_APP_HOME_SCREEN: null,
  UPDATE_BUILDER_SECTIONS_ORDER: null,
  UPDATE_BUILDER_SECTION: null,
  CREATE_NEW_BUILDER_SECTION: null,
  REMOVE_BUILDER_SECTION: null,
  CREATE_NEW_BUILDER_BLOCK: null,
  REMOVE_BUILDER_BLOCK: null,
  RECEIVE_BUILDER_CHANGES: null,
  CLEAR_BUILDER: null,

  RECEIVE_MENU_SUCCESS: null,
  CREATE_MENU_SUCCESS: null,
  CREATE_MENU_FAILURE: null,
  UPDATE_MENU_SUCCESS: null,
  CREATE_MENU_OFFLINE: null,
  UPDATE_MENU_OFFLINE: null,
  UPDATE_MENU_FAILURE: null,
  UPDATE_MENU_ITEMS_OFFLINE: null,
  DELETE_MENU_SUCCESS: null,
  RECEIVE_WEBSITE_MENUS_SUCCESS: null,
  RECEIVE_WEBSITE_MENUS_FAILURE: null,
  RECEIVE_MENU_ITEMS_FAILURE: null,
  CLEAR_SAVE_QUERY_STATUS: null,
  CLEAR_MENU_STORE: null,
  DUPLICATE_WEBSITE_SUCCESS: null,
  DUPLICATE_WEBSITE_FAILURE: null,
  DUPLICATE_WEBSITE_PENDING: null,
  DUPLICATE_WEBSITE_PAGE_SECTION_PENDING: null,
  DUPLICATE_WEBSITE_PAGE_SECTION_SUCCESS: null,
  DUPLICATE_WEBSITE_PAGE_SECTION_FAILURE: null,

  RELOAD_WEBSITE_THEME_REQUEST: null,
  RELOAD_WEBSITE_THEME_SUCCESS: null,
  RELOAD_WEBSITE_THEME_FAILURE: null,

  // Direct Email
  CREATE_DIRECT_EMAIL_REQUEST: null,
  CREATE_DIRECT_EMAIL_SUCCESS: null,
  CREATE_DIRECT_EMAIL_FAILURE: null,
  DELIVER_DIRECT_EMAIL_REQUEST: null,
  DELIVER_DIRECT_EMAIL_SUCCESS: null,
  DELIVER_DIRECT_EMAIL_FAILURE: null,
  DELIVER_BATCH_DIRECT_EMAIL_SUCCESS: null,
  DELIVER_BATCH_DIRECT_EMAIL_FAILURE: null,
  CLEAR_DIRECT_EMAIL_STATE: null,

  // email builder
  RECEIVE_EMAIL_TEMPLATE_SUCCESS: null,
  RECEIVE_EMAIL_TEMPLATE_FAILURE: null,
  UPDATE_EMAIL_TEMPLATE_REQUEST: null,
  UPDATE_EMAIL_TEMPLATE_SUCCESS: null,
  UPDATE_EMAIL_TEMPLATE_ERROR: null,
  UPDATE_EMAIL_SELECTED_ID: null,
  UPDATE_EMAIL_SECTIONS: null,
  CREATE_NEW_EMAIL_SECTION: null,
  REMOVE_EMAIL_SECTION: null,
  RECEIVE_EMAIL_TEMPLATE_CHANGES: null,
  DUPLICATE_EMAIL_TEMPLATE_SUCCESS: null,
  DUPLICATE_EMAIL_TEMPLATE_FAILURE: null,
  DUPLICATE_EMAIL_TEMPLATE_PENDING: null,
  RECEIVE_EMAIL_TEMPLATES_SUCCESS: null,
  RECEIVE_EMAIL_TEMPLATES_FAILURE: null,
  DELETE_EMAIL_TEMPLATE_SUCCESS: null,
  DELETE_EMAIL_TEMPLATE_FAILURE: null,
  CREATE_EMAIL_TEMPLATE_PENDING: null,
  CREATE_EMAIL_TEMPLATE_FAILURE: null,
  CREATE_EMAIL_TEMPLATE_SUCCESS: null,
  RELOAD_EMAIL_TEMPLATE_THEME_REQUEST: null,
  RELOAD_EMAIL_TEMPLATE_THEME_SUCCESS: null,
  RELOAD_EMAIL_TEMPLATE_THEME_FAILURE: null,
  CLEAR_EMAIL_TEMPLATE: null,
  FETCH_EMAIL_TEMPLATES_FROM_EVENT_SUCCESS: null,
  DUPLICATE_EMAIL_SECTION_PENDING: null,
  DUPLICATE_EMAIL_SECTION_SUCCESS: null,
  DUPLICATE_EMAIL_SECTION_FAILURE: null,
  RECEIVE_EMAIL_TEMPLATES_FOLDERS_SUCCESS: null,
  RECEIVE_EMAIL_TEMPLATES_FOLDERS_FAILURE: null,
  RECEIVE_DOCUMENT_TEMPLATES_FOLDERS_SUCCESS: null,
  RECEIVE_DOCUMENT_TEMPLATES_FOLDERS_FAILURE: null,
  MOVE_EMAIL_TEMPLATES_SUCCESS: null,
  MOVE_EMAIL_TEMPLATES_FAILURE: null,
  MOVE_DOCUMENT_TEMPLATES_SUCCESS: null,
  MOVE_DOCUMENT_TEMPLATES_FAILURE: null,
  CREATE_EMAIL_TEMPLATES_FOLDER_SUCCESS: null,
  CREATE_EMAIL_TEMPLATES_FOLDER_FAILURE: null,
  CREATE_DOCUMENT_TEMPLATES_FOLDER_SUCCESS: null,
  CREATE_DOCUMENT_TEMPLATES_FOLDER_FAILURE: null,
  DESTROY_EMAIL_TEMPLATES_FOLDER_SUCCESS: null,
  DESTROY_EMAIL_TEMPLATES_FOLDER_FAILURE: null,
  DESTROY_DOCUMENT_TEMPLATES_FOLDER_SUCCESS: null,
  DESTROY_DOCUMENT_TEMPLATES_FOLDER_FAILURE: null,
  UPDATE_EMAIL_TEMPLATES_FOLDER_SUCCESS: null,
  UPDATE_EMAIL_TEMPLATES_FOLDER_FAILURE: null,
  UPDATE_DOCUMENT_TEMPLATES_FOLDER_SUCCESS: null,
  UPDATE_DOCUMENT_TEMPLATES_FOLDER_FAILURE: null,

  // guest category request
  GUEST_CATEGORY_UPDATE_REQUEST: null,
  GUEST_CATEGORY_UPDATE_SUCCESS: null,
  GUEST_CATEGORY_UPDATE_FAILURE: null,
  GUEST_CATEGORY_CREATE_SUCCESS: null,
  GUEST_CATEGORY_CREATE_FAILURE: null,
  GUEST_CATEGORY_COPY_SUCCESS: null,
  GUEST_CATEGORY_COPY_FAILURE: null,
  GUEST_CATEGORY_REQUEST: null,
  GUEST_CATEGORY_SUCCESS: null,
  GUEST_CATEGORY_FAILURE: null,
  GUEST_CATEGORY_DESTROY_SUCCESS: null,
  GUEST_CATEGORY_DESTROY_FAILURE: null,
  GUEST_CATEGORY_DELETE_ALL_PEOPLE_SUCCESS: null,
  GUEST_CATEGORY_DELETE_ALL_PEOPLE_FAILURE: null,
  GUEST_CATEGORY_VALID_SUCCESS: null,
  GUEST_CATEGORY_VALID_FAILURE: null,
  GUEST_CATEGORY_COUNT_PEOPLE_SUCCESS: null,
  GUEST_CATEGORY_COUNT_PEOPLE_FAILURE: null,
  GUEST_CATEGORY_CUSTOM_EMAIL_SUCCESS: null,
  GUEST_CATEGORY_CUSTOM_EMAIL_FAILURE: null,
  GUEST_CATEGORY_CLEAR_CUSTOM_EMAIL: null,
  GUEST_CATEGORY_ADD_OFFLINE_ERRORS: null,
  FETCH_DEFAULT_GUEST_CATEGORY_REQUEST: null,
  FETCH_DEFAULT_GUEST_CATEGORY_SUCCESS: null,
  FETCH_DEFAULT_GUEST_CATEGORY_FAILURE: null,
  REGISTRATION_DATES_TOGGLE_REQUEST: null,
  REGISTRATION_DATES_TOGGLE_SUCCESS: null,
  REGISTRATION_DATES_TOGGLE_FAILURE: null,

  // registration forms request
  REGISTRATION_FORMS_REQUEST: null,
  REGISTRATION_FORMS_SUCCESS: null,
  REGISTRATION_FORMS_FAILURE: null,
  REGISTRATION_FORM_CREATE_REQUEST: null,
  REGISTRATION_FORM_CREATE_SUCCESS: null,
  REGISTRATION_FORM_CREATE_FAILURE: null,
  REGISTRATION_FORM_REQUEST: null,
  REGISTRATION_FORM_SUCCESS: null,
  REGISTRATION_FORM_FAILURE: null,
  REGISTRATION_FORM_UPDATE_REQUEST: null,
  REGISTRATION_FORM_UPDATE_SUCCESS: null,
  REGISTRATION_FORM_UPDATE_FAILURE: null,
  REGISTRATION_FORM_DELETE_CUSTOM_CSS_REQUEST: null,
  REGISTRATION_FORM_DELETE_CUSTOM_CSS_SUCCESS: null,
  REGISTRATION_FORM_DELETE_CUSTOM_CSS_FAILURE: null,
  REGISTRATION_FORM_DELETE_REQUEST: null,
  REGISTRATION_FORM_DELETE_SUCCESS: null,
  REGISTRATION_FORM_DELETE_FAILURE: null,
  REGISTRATION_FORM_DUPLICATE_SUCCESS: null,
  REGISTRATION_FORM_DUPLICATE_FAILURE: null,
  FETCH_REGISTRATION_FORMS_FROM_EVENT_SUCCESS: null,

  //registration form steps
  REGISTRATION_FORM_STEP_CLEAN_ERRORS: null,
  REGISTRATION_FORM_STEP_CREATE_SUCCESS: null,
  REGISTRATION_FORM_STEP_DELETE_FAILURE: null,
  REGISTRATION_FORM_STEP_DELETE_SUCCESS: null,
  REGISTRATION_FORM_STEP_TOGGLE_FOCUS: null,
  REGISTRATION_FORM_STEP_UPDATE_SUCCESS: null,
  REGISTRATION_FORM_STEP_UPDATE_FAILURE: null,
  REGISTRATION_FORM_STEP_DUPLICATE_REQUEST: null,
  REGISTRATION_FORM_STEP_DUPLICATE_SUCCESS: null,
  REGISTRATION_FORM_STEP_DUPLICATE_FAILURE: null,

  //registration forms items actions
  REGISTRATION_FORM_ITEM_NEW: null,
  REGISTRATION_FORM_ITEM_CREATE_REQUEST: null,
  REGISTRATION_FORM_ITEM_CREATE_SUCCESS: null,
  REGISTRATION_FORM_ITEM_CREATE_FAILURE: null,
  REGISTRATION_FORM_ITEM_UPDATE_REQUEST: null,
  REGISTRATION_FORM_ITEM_UPDATE_SUCCESS: null,
  REGISTRATION_FORM_ITEM_UPDATE_FAILURE: null,
  REGISTRATION_FORM_ITEM_DESTROY_REQUEST: null,
  REGISTRATION_FORM_ITEM_DESTROY_SUCCESS: null,
  REGISTRATION_FORM_ITEM_DESTROY_FAILURE: null,
  REGISTRATION_FORM_ITEM_TOGGLE_MODE: null,
  REGISTRATION_FORM_ITEM_DISPLAY_ERROR: null,
  REGISTRATION_FORM_ITEM_BATCH_CREATE_SUCCESS: null,
  REGISTRATION_FORM_ITEM_BATCH_CREATE_FAILURE: null,
  REGISTRATION_FORM_ITEMS_FETCH_REQUEST: null,
  REGISTRATION_FORM_ITEMS_FETCH_SUCCESS: null,
  REGISTRATION_FORM_ITEMS_FETCH_FAILURE: null,

  //registration form items options actions
  REGISTRATION_FORM_ITEM_OPTION_DESTROY_REQUEST: null,
  REGISTRATION_FORM_ITEM_OPTION_DESTROY_SUCCESS: null,
  REGISTRATION_FORM_ITEM_OPTION_DESTROY_FAILURE: null,

  // Guest actions
  FETCH_GUEST_REQUEST: null,
  RECEIVE_GUEST: null,
  RECEIVE_GUEST_FAILURE: null,
  SAMPLE_GUESTS_CREATION_SUCCESS: null,
  UPDATE_GUEST_REQUEST: null,
  UPDATE_GUEST: null,
  UPDATE_GUEST_FAILURE: null,
  CALCULATED_FIELD_PREVIEW_REQUEST: null,
  CALCULATED_FIELD_PREVIEW_SUCCESS: null,
  CALCULATED_FIELD_PREVIEW_FAILURE: null,

  // Contacts actions
  FETCH_CONTACT_REQUEST: null,
  FETCH_CONTACT_SUCCESS: null,
  FETCH_CONTACT_FAILURE: null,
  FETCH_CONTACT_HISTORY_REQUEST: null,
  FETCH_CONTACT_HISTORY_SUCCESS: null,
  FETCH_CONTACT_HISTORY_FAILURE: null,
  FETCH_CONTACT_ACTIVITIES_REQUEST: null,
  FETCH_CONTACT_ACTIVITIES_SUCCESS: null,
  FETCH_CONTACT_ACTIVITIES_FAILURE: null,
  CLEAR_CONTACT_STATE: null,
  GENERATE_EMAIL_CONFIRMATION_LINK_SUCCESS: null,


  // Contact fields actions
  FETCH_CONTACT_FIELDS_REQUEST: null,
  FETCH_CONTACT_FIELDS_SUCCESS: null,
  FETCH_CONTACT_FIELDS_FAILURE: null,

  // Guest fields
  GUEST_FIELDS_BATCH_CREATE_REQUEST: null,
  GUEST_FIELDS_BATCH_CREATE_SUCCESS: null,
  GUEST_FIELDS_BATCH_CREATE_FAILURE: null,

  //guest fields to export
  GUEST_FIELDS_TO_EXPORT_SUCCESS: null,

  //guest statistics
  GUESTS_STATISTICS_SUCCESS: null,
  GUESTS_STATISTICS_FILTERED: null,
  GUESTS_PAYMENT_STATISTICS_SUCCESS: null,
  GUESTS_STATISTICS_CLEAR: null,
  GUESTS_PAYMENT_STATISTICS_CLEAR: null,

  //cart items statistics
  CART_ITEMS_STATISTICS_SUCCESS: null,
  CART_ITEMS_STATISTICS_ERROR: null,
  CART_ITEMS_STATISTICS_CLEAR: null,

  // Accesspoint stats
  ACCESSPOINTS_GREEN_STATISTICS_SUCCESS: null,
  ACCESSPOINTS_RED_STATISTICS_SUCCESS: null,

  //guest count
  GUESTS_COUNT_TOTAL_SUCCESS: null,
  GUESTS_COUNT_SINCE_SUCCESS: null,
  GUESTS_COUNT_BETWEEN_SUCCESS: null,
  GUEST_COUNT_PER_REGISTER_DATE_SUCCESS: null,
  GUESTS_COUNT_CLEAR: null,

  //access control count
  ACCESS_CONTROL_COUNT_TOTAL_SUCCESS: null,
  ACCESS_CONTROL_COUNT_SINCE_SUCCESS: null,
  ACCESS_CONTROL_COUNT_BETWEEN_SUCCESS: null,
  ACCESS_CONTROL_COUNT_PER_HOUR_SUCCESS: null,

  //user settings
  USER_SETTING_CREATE_SUCCESS: null,
  USER_SETTING_CREATE_FAILURE: null,
  USER_SETTING_REQUEST: null,
  USER_SETTING_SUCCESS: null,
  USER_SETTING_FAILURE: null,

  //guest import operations
  GUEST_IMPORT_OPERATION_RECEIVE_REQUEST: null,
  GUEST_IMPORT_OPERATION_RECEIVE_SUCCESS: null,
  GUEST_IMPORT_OPERATION_RECEIVE_FAILURE: null,
  GUEST_IMPORT_OPERATION_CREATE_REQUEST: null,
  GUEST_IMPORT_OPERATION_CREATE_SUCCESS: null,
  GUEST_IMPORT_OPERATION_CREATE_FAILURE: null,
  GUEST_IMPORT_OPERATION_UPDATE_SUCCESS: null,
  GUEST_IMPORT_OPERATION_UPDATE_FAILURE: null,
  FETCH_GUEST_IMPORT_OPERATION_DISTINCT_VALUES: null,
  GUEST_IMPORT_OPERATION_DISTINCT_VALUES_SUCCESS: null,
  GUEST_IMPORT_OPERATION_DISTINCT_VALUES_FAILURE: null,
  CHECK_IMPORT_SIZE_REQUEST: null,
  CHECK_IMPORT_SIZE_SUCCESS: null,
  CHECK_IMPORT_SIZE_FAILURE: null,
  TRIGGER_EXTRACT_ROWS_REQUEST: null,
  TRIGGER_EXTRACT_ROWS_SUCCESS: null,
  TRIGGER_VERIFICATION_REQUEST: null,
  TRIGGER_VERIFICATION_SUCCESS: null,
  TRIGGER_VERIFICATION_FAILURE: null,
  CONFIRM_IMPORT_REQUEST: null,
  CONFIRM_IMPORT_SUCCESS: null,
  CONFIRM_IMPORT_FAILURE: null,
  CLEAR_GUEST_IMPORT_OPERATION_STORE: null,

  //accesspoints
  RECEIVE_ACCESSPOINT: null,
  RECEIVE_ACCESSPOINTS_REQUEST: null,
  RECEIVE_ACCESSPOINTS_SUCCESS: null,
  RECEIVE_ACCESSPOINTS_FAILURE: null,
  CREATE_ACCESSPOINT_SUCCESS: null,
  CREATE_ACCESSPOINT_FAILURE: null,
  UPDATE_ACCESSPOINT_SUCCESS: null,
  UPDATE_ACCESSPOINT_FAILURE: null,
  FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_SUCCESS: null,
  FETCH_ACCESSPOINT_CUSTOM_VALUES_FOR_SESSION_FAILURE: null,

  // document templates
  DOCUMENT_TEMPLATES_REQUEST: null,
  DOCUMENT_TEMPLATES_SUCCESS: null,
  DOCUMENT_TEMPLATES_FAILURE: null,
  UPDATE_DOCUMENT_SELECTED_ID: null,
  FETCH_DOCUMENT_TEMPLATES_FROM_EVENT_SUCCESS: null,
  FETCH_DOCUMENT_TEMPLATES_PRESETS_LIST_SUCCESS: null,

  // document template
  FETCH_DOCUMENT_TEMPLATE_PENDING: null,
  FETCH_DOCUMENT_TEMPLATE_SUCCESS: null,
  CREATE_DOCUMENT_TEMPLATE_PENDING: null,
  CREATE_DOCUMENT_TEMPLATE_SUCCESS: null,
  CREATE_DOCUMENT_TEMPLATE_FAILURE: null,
  UPDATE_DOCUMENT_TEMPLATE_PENDING: null,
  UPDATE_DOCUMENT_TEMPLATE_SUCCESS: null,
  UPDATE_DOCUMENT_TEMPLATE_FAILURE: null,
  DELETE_DOCUMENT_TEMPLATE_PENDING: null,
  DELETE_DOCUMENT_TEMPLATE_SUCCESS: null,
  DELETE_DOCUMENT_TEMPLATE_FAILURE: null,
  DUPLICATE_DOCUMENT_TEMPLATE_PENDING: null,
  DUPLICATE_DOCUMENT_TEMPLATE_SUCCESS: null,
  DUPLICATE_DOCUMENT_TEMPLATE_FAILURE: null,
  CLEAR_DOCUMENT_TEMPLATE_DUPLICATION_ERRORS: null,
  RELOAD_DOCUMENT_TEMPLATE_THEME_PENDING: null,
  RELOAD_DOCUMENT_TEMPLATE_THEME_SUCCESS: null,
  RELOAD_DOCUMENT_TEMPLATE_THEME_FAILURE: null,
  DUPLICATE_DOCUMENT_SECTION_PENDING: null,
  DUPLICATE_DOCUMENT_SECTION_SUCCESS: null,
  DUPLICATE_DOCUMENT_SECTION_FAILURE: null,

  // liquid_template
  RECEIVE_LIQUID_TEMPLATES_SUCCESS: null,
  RECEIVE_LIQUID_TEMPLATE_SUCCESS: null,
  SELECT_LIQUID_TEMPLATE_SUCCESS: null,
  CREATE_LIQUID_TEMPLATE_REQUEST: null,
  CREATE_LIQUID_TEMPLATE_SUCCESS: null,
  CREATE_LIQUID_TEMPLATE_FAILURE: null,
  UPDATE_LIQUID_TEMPLATE_REQUEST: null,
  UPDATE_LIQUID_TEMPLATE_SUCCESS: null,
  UPDATE_LIQUID_TEMPLATE_FAILURE: null,
  CLEAR_ERRORS: null,

  // richtext editor
  OFFLINE_UPDATE_RICHTEXT_EDITOR_SUCCESS: null,

  // ticketing
  CREATE_TICKETING_REQUEST: null,
  UPDATE_TICKETING_REQUEST: null,
  RECEIVE_TICKETING_SUCCESS: null,
  RECEIVE_TICKETING_FAILURE: null,
  CREATE_TICKETING_SUCCESS: null,
  CREATE_TICKETING_FAILURE: null,
  UPDATE_TICKETING_SUCCESS: null,
  UPDATE_TICKETING_FAILURE: null,

  // Frontend locales
  FETCH_TRANSLATIONS: null,
  FETCH_TRANSLATIONS_SUCCESS: null,
  FETCH_TRANSLATIONS_FAILURE: null,
  UPDATE_TRANSLATIONS: null,
  UPDATE_TRANSLATIONS_SUCCESS: null,
  UPDATE_TRANSLATIONS_FAILURE: null,
  CREATE_TRANSLATIONS: null,
  CREATE_TRANSLATIONS_SUCCESS: null,
  CREATE_TRANSLATIONS_FAILURE: null,
  AUTO_TRANSLATIONS: null,
  AUTO_TRANSLATIONS_SUCCESS: null,
  AUTO_TRANSLATIONS_FAILURE: null,
  DELETE_TRANSLATIONS: null,
  DELETE_TRANSLATIONS_SUCCESS: null,
  DELETE_TRANSLATIONS_FAILURE: null,
  OFFLINE_SET_TRANSLATION_TABLE: null,
  CLEAR_TRANSLATION_STATE: null,
  SELECT_TRANSLATION: null,
  FETCH_TRANSLATABLE_FIELDS_SUCCESS: null,

  // Column Set
  FETCH_COLUMNS_SETS_REQUEST: null,
  FETCH_COLUMNS_SETS_SUCCESS: null,
  FETCH_COLUMNS_SETS_FAILURE: null,
  CREATE_COLUMNS_SET_REQUEST: null,
  CREATE_COLUMNS_SET_SUCCESS: null,
  CREATE_COLUMNS_SET_FAILURE: null,
  UPDATE_COLUMNS_SET_REQUEST: null,
  UPDATE_COLUMNS_SET_SUCCESS: null,
  UPDATE_COLUMNS_SET_FAILURE: null,
  DELETE_COLUMNS_SET_REQUEST: null,
  DELETE_COLUMNS_SET_SUCCESS: null,
  DELETE_COLUMNS_SET_FAILURE: null,
  CHANGE_SELECTED_COLUMNS_SET_ID: null,
  // Assets Manager
  FETCH_ASSETS_FOLDERS_REQUEST: null,
  FETCH_ASSETS_FOLDERS_SUCCESS: null,
  FETCH_ASSETS_FOLDERS_FAILURE: null,
  FETCH_ASSETS_FOLDER_REQUEST: null,
  FETCH_ASSETS_FOLDER_SUCCESS: null,
  FETCH_ASSETS_FOLDER_FAILURE: null,
  CREATE_ASSETS_FOLDER_REQUEST: null,
  CREATE_ASSETS_FOLDER_SUCCESS: null,
  CREATE_ASSETS_FOLDER_FAILURE: null,
  FETCH_ASSETS_REQUEST: null,
  FETCH_ASSETS_SUCCESS: null,
  FETCH_ASSETS_FAILURE: null,
  CREATE_ASSET_REQUEST: null,
  CREATE_ASSET_SUCCESS: null,
  CREATE_ASSET_FAILURE: null,
  DELETE_ASSET_REQUEST: null,
  DELETE_ASSET_SUCCESS: null,
  DELETE_ASSET_FAILURE: null,
  UPDATE_ASSET_REQUEST: null,
  UPDATE_ASSET_SUCCESS: null,
  UPDATE_ASSET_FAILURE: null,
  // Streaming Archives Manager
  FETCH_STREAMING_ARCHIVES_REQUEST: null,
  FETCH_STREAMING_ARCHIVES_SUCCESS: null,
  FETCH_STREAMING_ARCHIVES_FAILURE: null,
  CREATE_STREAMING_ARCHIVE_REQUEST: null,
  CREATE_STREAMING_ARCHIVE_SUCCESS: null,
  CREATE_STREAMING_ARCHIVE_FAILURE: null,
  DELETE_STREAMING_ARCHIVE_REQUEST: null,
  DELETE_STREAMING_ARCHIVE_SUCCESS: null,
  DELETE_STREAMING_ARCHIVE_FAILURE: null,
  UPDATE_STREAMING_ARCHIVE_REQUEST: null,
  UPDATE_STREAMING_ARCHIVE_SUCCESS: null,
  UPDATE_STREAMING_ARCHIVE_FAILURE: null,
  FETCH_STREAMING_ARCHIVE_REQUEST: null,
  FETCH_STREAMING_ARCHIVE_SUCCESS: null,
  FETCH_STREAMING_ARCHIVE_FAILURE: null,

  // Guest products
  FETCH_GUEST_PRODUCTS_REQUEST: null,
  FETCH_GUEST_PRODUCTS_SUCCESS: null,
  FETCH_GUEST_PRODUCTS_FAILURE: null,

  // Guest products statistics
  FETCH_GUEST_PRODUCTS_STATISTICS_REQUEST: null,
  FETCH_GUEST_PRODUCTS_STATISTICS_SUCCESS: null,
  FETCH_GUEST_PRODUCTS_STATISTICS_FAILURE: null,

  // Guest products by information requests statistics
  FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_REQUEST: null,
  FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS: null,
  FETCH_GUEST_PRODUCTS_INFORMATION_REQUESTS_STATISTICS_FAILURE: null,

  // Guests by information request statistics
  FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_REQUEST: null,
  FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_SUCCESS: null,
  FETCH_GUESTS_INFORMATION_REQUESTS_STATISTICS_FAILURE: null,

  // Sessions statistics
  FETCH_SESSIONS_STATISTICS_REQUEST: null,
  FETCH_SESSIONS_STATISTICS_SUCCESS: null,
  FETCH_SESSIONS_STATISTICS_FAILURE: null,

  // Exhibitors statistics
  FETCH_EXHIBITORS_STATISTICS_REQUEST: null,
  FETCH_EXHIBITORS_STATISTICS_SUCCESS: null,
  FETCH_EXHIBITORS_STATISTICS_FAILURE: null,

  // Exhibitor leads statistics
  FETCH_EXHIBITORS_LEADS_STATISTICS_REQUEST: null,
  FETCH_EXHIBITORS_LEADS_STATISTICS_SUCCESS: null,
  FETCH_EXHIBITORS_LEADS_STATISTICS_FAILURE: null,

  // Guest invitation statistics
  FETCH_GUEST_INVITATION_STATS_REQUEST: null,
  FETCH_GUEST_INVITATION_STATS_SUCCESS: null,
  FETCH_GUEST_INVITATION_STATS_FAILURE: null,

  // Guest invitation statistics
  FETCH_GUEST_PROMO_CODE_INVITATION_STATS_REQUEST: null,
  FETCH_GUEST_PROMO_CODE_INVITATION_STATS_SUCCESS: null,
  FETCH_GUEST_PROMO_CODE_INVITATION_STATS_FAILURE: null,

  // Meeting appointments statistics
  FETCH_MEETING_APPOINTMENTS_STATISTICS_REQUEST: null,
  FETCH_MEETING_APPOINTMENTS_STATISTICS_SUCCESS: null,
  FETCH_MEETING_APPOINTMENTS_STATISTICS_FAILURE: null,

  // Messages statistics
  FETCH_MESSAGES_STATISTICS_REQUEST: null,
  FETCH_MESSAGES_STATISTICS_SUCCESS: null,
  FETCH_MESSAGES_STATISTICS_FAILURE: null,

  // Custom Emails
  FETCH_CUSTOM_EMAILS_REQUEST: null,
  FETCH_CUSTOM_EMAILS_SUCCESS: null,
  FETCH_CUSTOM_EMAILS_FAILURE: null,
  CREATE_CUSTOM_EMAIL_REQUEST: null,
  CREATE_CUSTOM_EMAIL_SUCCESS: null,
  CREATE_CUSTOM_EMAIL_FAILURE: null,
  UPDATE_CUSTOM_EMAIL_REQUEST: null,
  UPDATE_CUSTOM_EMAIL_SUCCESS: null,
  UPDATE_CUSTOM_EMAIL_FAILURE: null,
  CLEAR_CUSTOM_EMAIL_STATE: null,

  // Report Configurations
  CREATE_REPORT_CONFIGURATION_REQUEST: null,
  CREATE_REPORT_CONFIGURATION_SUCCESS: null,
  CREATE_REPORT_CONFIGURATION_FAILURE: null,

  FETCH_REPORT_CONFIGURATIONS_REQUEST: null,
  FETCH_REPORT_CONFIGURATIONS_SUCCESS: null,

  DELETE_REPORT_CONFIGURATIONS_SUCCESS: null,

  // Occupancy dashboard
  FETCH_ACCESSPOINTS_OCCUPANCY_REQUEST: null,
  FETCH_ACCESSPOINTS_OCCUPANCY_SUCCESS: null,
  FETCH_ACCESSPOINTS_OCCUPANCY_FAILURE: null,

  // Live consumption
  FETCH_LIVE_CONSUMPTION_REQUEST: null,
  FETCH_LIVE_CONSUMPTION_SUCCESS: null,
  FETCH_LIVE_CONSUMPTION_FAILURE: null,

  // Clean SiloedData
  DELETE_GUEST_PRODUCT_KEY_IN_SILOED_DATA: null,
  DELETE_GUEST_KEY_IN_SILOED_DATA: null,
  DELETE_ACCESSPOINT_KEY_IN_SILOED_DATA: null,

  // FeatureSet
  FETCH_FEATURE_SET_REQUEST: null,
  FETCH_FEATURE_SET_SUCCESS: null,
  FETCH_FEATURE_SET_FAILURE: null,
  CREATE_FEATURE_SET_REQUEST: null,
  CREATE_FEATURE_SET_SUCCESS: null,
  CREATE_FEATURE_SET_FAILURE: null,
  UPDATE_FEATURE_SET_REQUEST: null,
  UPDATE_FEATURE_SET_SUCCESS: null,
  UPDATE_FEATURE_SET_FAILURE: null,

  // Session rooms
  FETCH_SESSION_ROOMS_REQUEST: null,
  FETCH_SESSION_ROOMS_SUCCESS: null,
  FETCH_SESSION_ROOMS_FAILURE: null,
  FETCH_SESSION_ROOM_REQUEST: null,
  FETCH_SESSION_ROOM_SUCCESS: null,
  FETCH_SESSION_ROOM_FAILURE: null,
  CREATE_SESSION_ROOM_REQUEST: null,
  CREATE_SESSION_ROOM_SUCCESS: null,
  CREATE_SESSION_ROOM_FAILURE: null,
  UPDATE_SESSION_ROOM_REQUEST: null,
  UPDATE_SESSION_ROOM_SUCCESS: null,
  UPDATE_SESSION_ROOM_FAILURE: null,

  // guest category accesspoints
  CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST: null,
  CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS: null,
  CREATE_OR_UPDATE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE: null,
  DELETE_GUEST_CATEGORY_ACCESSPOINTS_REQUEST: null,
  DELETE_GUEST_CATEGORY_ACCESSPOINTS_SUCCESS: null,
  DELETE_GUEST_CATEGORY_ACCESSPOINTS_FAILURE: null,

  // blog article
  FETCH_BLOG_ARTICLE_PENDING: null,
  FETCH_BLOG_ARTICLE_SUCCESS: null,
  CREATE_BLOG_ARTICLE_PENDING: null,
  CREATE_BLOG_ARTICLE_SUCCESS: null,
  CREATE_BLOG_ARTICLE_FAILURE: null,
  UPDATE_BLOG_ARTICLE_PENDING: null,
  UPDATE_BLOG_ARTICLE_SUCCESS: null,
  UPDATE_BLOG_ARTICLE_FAILURE: null,
  CLEAR_BLOG_ARTICLE: null,
  DELETE_BLOG_ARTICLE_PENDING: null,
  DELETE_BLOG_ARTICLE_SUCCESS: null,
  DELETE_BLOG_ARTICLE_FAILURE: null,
  DUPLICATE_BLOG_ARTICLE_PENDING: null,
  DUPLICATE_BLOG_ARTICLE_SUCCESS: null,
  DUPLICATE_BLOG_ARTICLE_FAILURE: null,
  CLEAR_BLOG_ARTICLE_DUPLICATION_ERRORS: null,

  // blog articles
  FETCH_BLOG_ARTICLES_PENDING: null,
  FETCH_BLOG_ARTICLES_SUCCESS: null,
  FETCH_BLOG_ARTICLES_COUNT_SUCCESS: null,

  // programs
  FETCH_PROGRAMS_REQUEST: null,
  FETCH_PROGRAMS_SUCCESS: null,
  CREATE_PROGRAM_REQUEST: null,
  CREATE_PROGRAM_SUCCESS: null,
  CREATE_PROGRAM_FAILURE: null,
  UPDATE_PROGRAM_REQUEST: null,
  UPDATE_PROGRAM_SUCCESS: null,
  UPDATE_PROGRAM_FAILURE: null,
  DELETE_PROGRAM_REQUEST: null,
  DELETE_PROGRAM_SUCCESS: null,
  DELETE_PROGRAM_FAILURE: null,
  FETCH_FILTER_METADATA_REQUEST: null,
  FETCH_FILTER_METADATA_SUCCESS: null,

  // meeting locations
  FETCH_MEETING_LOCATIONS_REQUEST: null,
  FETCH_MEETING_LOCATIONS_SUCCESS: null,

  // leads
  FETCH_MOBINETWORK_CONFIGURATION_REQUEST: null,
  FETCH_MOBINETWORK_CONFIGURATION_SUCCESS: null,
  UPDATE_MOBINETWORK_CONFIGURATION_REQUEST: null,
  UPDATE_MOBINETWORK_CONFIGURATION_SUCCESS: null,
  UPDATE_MOBINETWORK_CONFIGURATION_ERROR: null,
  LEAD_QUALIFICATION_FIELDS_SOFT_UPDATE: null,

  // workflows
  WORKFLOWS_REQUEST: null,
  WORKFLOWS_SUCCESS: null,
  WORKFLOWS_FAILURE: null,
  FETCH_WORKFLOW_REQUEST: null,
  FETCH_WORKFLOW_SUCCESS: null,
  FETCH_WORKFLOW_FAILURE: null,
  FETCH_WORKFLOW_VERSIONS_REQUEST: null,
  FETCH_WORKFLOW_VERSIONS_SUCCESS: null,
  FETCH_WORKFLOW_VERSION_REQUEST: null,
  FETCH_WORKFLOW_VERSION_SUCCESS: null,
  FETCH_WORKFLOW_VERSION_FAILURE: null,
  FETCH_WORKFLOW_STRATEGY_REQUEST: null,
  FETCH_WORKFLOW_STRATEGY_SUCCESS: null,
  FETCH_WORKFLOW_STRATEGY_FAILURE: null,
  CREATE_WORKFLOW_PENDING: null,
  CREATE_WORKFLOW_SUCCESS: null,
  CREATE_WORKFLOW_FAILURE: null,
  CREATE_WORKFLOW_VERSION_PENDING: null,
  CREATE_WORKFLOW_VERSION_SUCCESS: null,
  CREATE_WORKFLOW_VERSION_FAILURE: null,
  UPDATE_WORKFLOW_PENDING: null,
  UPDATE_WORKFLOW_SUCCESS: null,
  UPDATE_WORKFLOW_FAILURE: null,
  UPDATE_WORKFLOW_VERSION_PENDING: null,
  UPDATE_WORKFLOW_VERSION_SUCCESS: null,
  UPDATE_WORKFLOW_VERSION_FAILURE: null,
  PUBLISH_WORKFLOW_VERSION_PENDING: null,
  PUBLISH_WORKFLOW_VERSION_SUCCESS: null,
  PUBLISH_WORKFLOW_VERSION_FAILURE: null,
  DELETE_WORKFLOW_PENDING: null,
  DELETE_WORKFLOW_SUCCESS: null,
  DELETE_WORKFLOW_FAILURE: null,
  FETCH_WORKFLOW_RUNS_REQUEST: null,
  FETCH_WORKFLOW_RUNS_SUCCESS: null,
  FETCH_WORKFLOW_RUN_REQUEST: null,
  FETCH_WORKFLOW_RUN_SUCCESS: null,
  FETCH_WORKFLOW_RUNS_COUNT_SUCCESS: null,
  RESET_WORKFLOW_RUNS_COUNTED_ALL: null,
  RESET_SELECTED_WORKFLOW_RUN: null,
  DELETE_WORKFLOW_RUN_SUCCESS: null,
  REPLAY_WORKFLOW_RUN_SUCCESS: null,
  BATCH_DELETE_WORKFLOW_RUNS_PENDING: null,
  BATCH_DELETE_WORKFLOW_RUNS_SUCCESS: null,
  BATCH_REPLAY_WORKFLOW_RUNS_PENDING: null,
  BATCH_REPLAY_WORKFLOW_RUNS_SUCCESS: null,
  CLEAR_WORKFLOW_BUILDER: null,

  // workflow steps
  ADD_TRIGGER_STEP_OFFLINE: null,
  ADD_ACTION_STEP_OFFLINE: null,
  ADD_FILTER_STEP_OFFLINE: null,
  CHANGE_STEP_STRATEGY: null,
  CHANGE_ACTIVE_STEP_ID: null,
  RECEIVE_WORKFLOW_STEP_CHANGES: null,
  CREATE_WORKFLOW_STEP_PENDING: null,
  CREATE_WORKFLOW_STEP_SUCCESS: null,
  CREATE_WORKFLOW_STEP_FAILURE: null,
  UPDATE_WORKFLOW_STEP_PENDING: null,
  UPDATE_WORKFLOW_STEP_SUCCESS: null,
  UPDATE_WORKFLOW_STEP_FAILURE: null,
  DELETE_WORKFLOW_STEP_PENDING: null,
  DELETE_WORKFLOW_STEP_SUCCESS: null,
  DELETE_WORKFLOW_STEP_FAILURE: null,

  // atlas jwt tokens
  FETCH_EVENT_ATLAS_JWT_TOKEN_REQUEST: null,
  FETCH_EVENT_ATLAS_JWT_TOKEN_SUCCESS: null,

  STORE_GUEST_IN_GUESTS: null,

  // guest product collections
  FETCH_GUEST_PRODUCT_COLLECTIONS_REQUEST: null,
  FETCH_GUEST_PRODUCT_COLLECTIONS_SUCCESS: null,
  UPDATE_GUEST_PRODUCT_COLLECTION_REQUEST: null,
  UPDATE_GUEST_PRODUCT_COLLECTION_SUCCESS: null,
  UPDATE_GUEST_PRODUCT_COLLECTION_ERROR: null,
  CREATE_GUEST_PRODUCT_COLLECTION_REQUEST: null,
  CREATE_GUEST_PRODUCT_COLLECTION_SUCCESS: null,
  CREATE_GUEST_PRODUCT_COLLECTION_ERROR: null,
  DELETE_GUEST_PRODUCT_COLLECTION_SUCCESS: null,
  DELETE_GUEST_PRODUCT_COLLECTION_ERROR: null,
  FETCH_ITEMS_COLLECTION_COUNT_SUCCESS: null,
  RESET_GUEST_PRODUCT_COLLECTION_STATE: null,
  EXPORT_ITEMS_COLLECTION_REQUEST: null,
  EXPORT_ITEMS_COLLECTION_SUCCESS: null,
  EXPORT_ITEMS_COLLECTION_ERROR: null,

  // badge template
  FETCH_BADGE_TEMPLATE_REQUEST: null,
  FETCH_BADGE_TEMPLATE_SUCCESS: null,
  FETCH_BADGE_TEMPLATE_FAILURE: null,
  CREATE_BADGE_TEMPLATE_REQUEST: null,
  CREATE_BADGE_TEMPLATE_SUCCESS: null,
  CREATE_BADGE_TEMPLATE_FAILURE: null,
  UPDATE_BADGE_TEMPLATE_REQUEST: null,
  UPDATE_BADGE_TEMPLATE_SUCCESS: null,
  UPDATE_BADGE_TEMPLATE_FAILURE: null,

  // salesforce configuration
  FETCH_SALESFORCE_CONFIGURATION_SUCCESS: null,
  UPDATE_SALESFORCE_CONFIGURATION_SUCCESS: null,
  UPDATE_SALESFORCE_CONFIGURATION_PENDING: null,
  UPDATE_SALESFORCE_CONFIGURATION_FAILURE: null,

  // user
  FETCH_CURRENT_USER_REQUEST: null,
  FETCH_CURRENT_USER_SUCCESS: null,
  FETCH_CURRENT_USER_FAILURE: null,
  UPDATE_CURRENT_USER_REQUEST: null,
  UPDATE_CURRENT_USER_SUCCESS: null,
  UPDATE_CURRENT_USER_FAILURE: null,
  DELETE_CURRENT_USER_REQUEST: null,
  DELETE_CURRENT_USER_SUCCESS: null,
  DELETE_CURRENT_USER_FAILURE: null,
  UPDATE_CURRENT_USER_PASSWORD_REQUEST: null,
  UPDATE_CURRENT_USER_PASSWORD_SUCCESS: null,
  UPDATE_CURRENT_USER_PASSWORD_FAILURE: null
});

export const chartStyle = {
  height: 350,
  width: 1000,
  marginLeft: 50,
  marginRight: 20,
  marginBottom: 50,
  marginTop: 20
};

export const BootstrapGutter = 20;

export const DayLabels = [
  I18n.t("react.registration_date.calendar.day.sunday"),
  I18n.t("react.registration_date.calendar.day.monday"),
  I18n.t("react.registration_date.calendar.day.tuesday"),
  I18n.t("react.registration_date.calendar.day.wednesday"),
  I18n.t("react.registration_date.calendar.day.thursday"),
  I18n.t("react.registration_date.calendar.day.friday"),
  I18n.t("react.registration_date.calendar.day.saturday")
];

export const MonthLabels = [
  I18n.t("react.registration_date.calendar.month.january"),
  I18n.t("react.registration_date.calendar.month.february"),
  I18n.t("react.registration_date.calendar.month.march"),
  I18n.t("react.registration_date.calendar.month.april"),
  I18n.t("react.registration_date.calendar.month.may"),
  I18n.t("react.registration_date.calendar.month.june"),
  I18n.t("react.registration_date.calendar.month.july"),
  I18n.t("react.registration_date.calendar.month.august"),
  I18n.t("react.registration_date.calendar.month.september"),
  I18n.t("react.registration_date.calendar.month.october"),
  I18n.t("react.registration_date.calendar.month.november"),
  I18n.t("react.registration_date.calendar.month.december")
];

export const SearchQueryKeywords = {
  ACCEPTED_AT: "accepted_at",
  ARRIVAL_DATE: "first_attended_date",
  ATTENDED_DATE: "attended_date",
  CHECKED_IN_AT: "checked_in_at",
  COUNTRY_NAME: "country_name",
  COUNTRY: "country",
  EMAIL_BLOCKED: "email_blocked",
  EMAIL_BOUNCED: "email_bounced",
  EMAIL_CLICKED: "email_clicked",
  EMAIL_DELIVERED: "email_delivered",
  EMAIL_OPENED: "email_opened",
  EMAIL_SENT: "email_sent",
  EMAIL_SPAMMED: "email_spammed",
  EXPECTED_AT: "expected_at",
  GUEST_CATEGORY: "guest_category",
  GUEST_STATUS: "status",
  SALESFORCE_SYNC_STATUS: "salesforce_sync_status",
  INTEREST_IN: "interest_in",
  LABEL: "label",
  LOCALE: "locale",
  NO: "no",
  ORDER_UID: "order_uid",
  PAYMENT_STATUS: "payment_status",
  POPULATION: "population",
  PROMO_CODE: "promo_code",
  REGISTERED_AFTER: "registered_after",
  REGISTERED_BEFORE: "registered_before",
  REJECTED_AT: "rejected_at",
  SHOWED_UP_STATUS: "showed_up",
  SORT: "sort",
  SSO_STATUS: "sso_status",
  THEMATICS: "thematics",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_MEDIUM: "utm_medium",
  UTM_SOURCE: "utm_source",
  EVENT_CAMPAIGN_OPTIN: "event_campaign_optin",
  ACCOUNT_CAMPAIGN_OPTIN: "account_campaign_optin",
  ENGAGEMENT_LEVELS: "engagement_levels",
  CAMPAIGN_OPTIN: "campaign_optin",
  PRESENT_AT: "present_at",
  ATTENDANCE_TYPE: "attendance_type",
  INCLUDE_TAKEOUT: "include_takeout"
};

export const ProgramFilterTypes = {
  SESSION_TYPE: "session_type",
  ATTENDANCE_TYPE: "attendance_type",
  THEMATIC: "thematic",
  SESSION_ROOM: "session_room",
  DATE_RANGE: "date_range",
  TRAIT: "trait",
  BOOKMARKS_ONLY: "bookmarks_only",
  MISSED_SESSIONS: "missed_sessions",
  SORT_BY_RECOMMENDATION: "sort_by_recommendation",
};

export const BOOLEAN_PROGRAM_FILTER_KEYS = ["bookmarks_only", "missed_sessions", "sort_by_recommendation"];

export const PROGRAM_FILTER_KEY_FOR_FILTER_TYPE = {
  "attendance_type": "attendance_types",
  "session_type": "session_types",
  "session_room": "session_rooms",
  "thematic": "thematics",
  "date_range": "dates"
};

export const emailActivityFilters = [
  SearchQueryKeywords.EMAIL_SENT,
  SearchQueryKeywords.EMAIL_DELIVERED,
  SearchQueryKeywords.EMAIL_OPENED,
  SearchQueryKeywords.EMAIL_CLICKED,
  SearchQueryKeywords.EMAIL_BOUNCED,
  SearchQueryKeywords.EMAIL_SPAMMED,
  SearchQueryKeywords.EMAIL_BLOCKED
];

export const optinsFilters = [
  SearchQueryKeywords.EVENT_CAMPAIGN_OPTIN,
  SearchQueryKeywords.ACCOUNT_CAMPAIGN_OPTIN
];

export const optinsKeywords = optinsFilters.concat([
  SearchQueryKeywords.CAMPAIGN_OPTIN
]);

export const GuestStatusRegistered = "registered";
export const GuestStatusPendingRegistration = "pending_registration";
export const GuestStatusRejected = "rejected";
export const GuestStatusImported = "imported";
export const GuestStatusInProgress = "in_progress";
export const GuestStatusInvited = "invited";
export const GuestStatusDeclined = "declined";
export const GuestStatusCancelled = "cancelled";
export const GuestStatuses = [GuestStatusRegistered, GuestStatusImported, GuestStatusInvited, GuestStatusPendingRegistration, GuestStatusRejected, GuestStatusInProgress, GuestStatusDeclined, GuestStatusCancelled];

export const GuestSSOStatusHasAccount = "has_account";
export const GuestSSOStatusUnconfirmedAccount = "unconfirmed_account";
export const GuestSSOStatusNoAccount = "no_account";
export const GuestSSOStatuses = [GuestSSOStatusHasAccount, GuestSSOStatusUnconfirmedAccount, GuestSSOStatusNoAccount];

export const PaymentStatuses = ["UNPAID", "FREE", "PARTIALLY_PAID", "PAID", "PENDING", "CANCELLED", "DECLINED", "REFUNDED"];
export const ShowedUpStatuses = [
  { name: "showed_up", value: "true" },
  { name: "not_showed_up", value: "false" }
];

export const GuestSortOptions = {
  "created-desc": null,
  "created-asc": null,
  "registered-desc": null,
  "registered-asc": null,
  "lastname-asc": null,
  "companyname-asc": null,
  "rank-asc": null,
  "engagement-desc": { requiredFeatures: [ENGAGEMENT_SCORING] },
  "engagement-asc": { requiredFeatures: [ENGAGEMENT_SCORING] },
};
export const DefaultGuestsListQuery = "status:registered sort:registered-desc";
export const ImportedGuestsListQuery = "status:imported sort:created-desc";
export const TEMP_ID_PREFIX = "temp";

export const TextFieldValidations = {
  text: [
    {
      key: "email",
      type: "format"
    },
    {
      key: "phone_number",
      type: "format"
    },
    {
      key: "url",
      type: "format"
    }
  ],
  length: [
    {
      key: "min_length",
      value: 0,
      valueUpdatable: true
    },
    {
      key: "exact_length",
      value: 0,
      valueUpdatable: true
    },
    {
      key: "max_length",
      value: 0,
      valueUpdatable: true
    }
  ],
  number: [
    {
      key: "integer",
      type: "format"
    }
  ]
};

export const TextFieldNormalizations = [
  "uppercase",
  "lowercase",
  "capitalize"
];

export const FormItemReadonlyOptions = [
  "no",
  "always",
  "edit_only"
];

export const GuestFieldFile = "file";
export const FormItemTypeHiddenField = "hidden_field";
export const ValueList = "value_list";
export const TextType = "text";
export const ImageType = "image";
export const CountryType = "country";
export const CalculatedType = "calculated";
export const LazyCalculatedType = "lazy_calculated";

export const CountryGuestField = "country";
export const CheckinPointGroupType = "checkin_point_group";
export const ProductGroupType = "product_group";
export const PromoCodeType = "promo_code";

export const FormItemTypes = [
  "text_field",
  "text_area",
  "dropdown",
  "checkbox_group",
  "radio_group",
  CheckinPointGroupType,
  ProductGroupType,
  "accommodation",
  PromoCodeType,
  "merchant_type",
  "thematics",
  "date_picker",
  GuestFieldFile,
  FormItemTypeHiddenField,
  CountryType
];

export const CreateNewAccesspointId = "-123456";

export const FormItemTypeWithNoGuestFields = [
  CheckinPointGroupType,
  ProductGroupType,
  "promo_code",
  "merchant_type"
];

export const FormItemTypesInQuestionFormItem = difference(FormItemTypes, [CountryType]);
export const CountryTypeDisplayAsOptions = ["dropdown", FormItemTypeHiddenField];

export const FormItemsWithDataValidation = [
  "text_field", "text_area"
];

export const FormItemsWithMinMaxValidation = [
  CheckinPointGroupType, ProductGroupType
];

export const FormItemsWithConditions = [
  CheckinPointGroupType,
  ProductGroupType
];

export const FormItemWithoutGuestField = [
  "promo_code",
  "merchant_type",
  "subform",
  "tickets_selector",
  "thematics"
];

export const FormItemsWithDefaultValue = [
  "text_field",
  "dropdown",
  "radio_group",
  CheckinPointGroupType,
  ProductGroupType
];

export const FormItemsWithDefaultValueOption = [
  "text_field"
];

export const FormItemsWithoutConditionalDisplay = [
  "tickets_selector"
];

export const FormItemGrouped = [
  "checkbox_group",
  CheckinPointGroupType,
  ProductGroupType,
  "accommodation"
];

export const DecoratingFormItem = [
  "paragraph",
  "title",
  "image",
  "subform",
  "tickets_selector"
];

export const FormItemsWithText = [
  "text_field",
  "text_area",
  "checkbox_group",
  CheckinPointGroupType,
  FormItemTypeHiddenField
];

export const FormItemWithTextCompletion = [
  "text_area"
];

export const FormItemsWithFile = [
  "file"
];

export const GuestFieldTypesWithFile = [
  "file",
  "image"
];

export const StaticGuestFieldsWithFile = [
  "avatar"
];

export const FormItemsWithValueList = [
  "dropdown", "radio_group"
];

export const FormItemTypesForSubformCopy = [
  "text_field",
  "text_area",
  "dropdown",
  "radio_group",
  "checkbox_group",
  "date_picker",
  "promo_code",
  FormItemTypeHiddenField,
  CountryType
];

export const ExpectedGuestFieldsByFormItem = {
  text_field: [TextType],
  text_area: [TextType],
  checkbox_group: [TextType],
  checkin_point_group: [TextType],
  product_group: [TextType],
  file: [GuestFieldFile],
  dropdown: [ValueList],
  radio_group: [ValueList],
  image: [ImageType],
  country: [TextType],
  date_picker: [TextType]
};

export const FormItemsDefault = {
  text_field: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    input_format: "simple",
    type: "text_field",
    key: "",
    label: "",
    options: {
      help: ""
    },
    display_conditions: []
  },
  text_area: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "",
    type: "text_area",
    label: "",
    options: {
      help: ""
    },
    display_conditions: []
  },
  promo_code: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "payment_promo_code",
    type: "promo_code",
    label: "",
    options: {
      help: ""
    },
    display_conditions: []
  },
  merchant_type: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "payment_merchant_type",
    type: "merchant_type",
    label: "",
    options: {
      help: ""
    },
    display_conditions: []
  },
  dropdown: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "",
    type: "dropdown",
    label: "",
    form_item_options: [],
    options: {
      help: ""
    },
    display_conditions: []
  },
  checkbox_group: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "",
    type: "checkbox_group",
    label: "",
    form_item_options: [
      {
        _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
        label: "Checkbox 1",
        key: "",
        is_default_value: false,
        rank: 1000
      }
    ],
    options: {
      help: ""
    },
    display_conditions: []
  },
  radio_group: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "",
    type: "radio_group",
    label: "",
    form_item_options: [],
    options: {
      help: ""
    },
    display_conditions: []
  },
  checkin_point_group: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: CheckinPointGroupType,
    key: "",
    label: "",
    form_item_options: [],
    options: {
      help: ""
    },
    display_conditions: []
  },
  product_group: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: ProductGroupType,
    key: "",
    label: "",
    form_item_options: [],
    options: {
      help: ""
    },
    display_conditions: []
  },
  accommodation: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: "accommodation",
    key: "",
    label: "",
    form_item_options: [],
    options: {
      help: ""
    },
    display_conditions: []
  },
  file: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: "file",
    options: {
      content: I18n.t("react.registration_forms.default_file_label"),
      help: ""
    },
    display_conditions: []
  },
  paragraph: {
    type: "paragraph",
    options: {
      content: "",
      draftjsContent: {}
    },
    display_conditions: []
  },
  title: {
    type: "title",
    options: {
      content: I18n.t("react.registration_forms.default_title")
    },
    display_conditions: []
  },
  image: {
    type: "image",
    options: {
      width: "auto",
      height: "auto"
    },
    display_conditions: []
  },
  hidden_field: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: "hidden_field",
    options: {
      default_value: "",
      help: ""
    },
    display_conditions: []
  },
  country: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: "country",
    options: {
      help: ""
    },
    display_conditions: []
  },
  date_picker: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: "date_picker",
    options: {
      help: ""
    },
    display_conditions: []
  },
  tickets_selector: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    type: "tickets_selector",
    options: {
      help: ""
    },
    display_conditions: []
  },
  thematics: {
    _id: `${TEMP_ID_PREFIX}_${new Date().valueOf()}`,
    key: "thematic_ids",
    type: "thematics",
    label: "",
    form_item_options: [],
    options: {
      help: ""
    },
    display_conditions: []
  }
};

export const AVAILABLE_OPERATORS_FOR_FORM_BUILDER = {
  "equal": { translationKey: "equals_to" },
  "nequal": { translationKey: "not_equals_to" },
  "startswith": { translationKey: "starts_with" },
  "endswith": { translationKey: "ends_with" },
  "contains": { translationKey: "contains" },
  "doesnotcontain": { translationKey: "does_not_contain" },
  "greaterthan": { translationKey: "greater_than" },
  "greaterthanorequal": { translationKey: "greater_than_or_equal" },
  "lesserthan": { translationKey: "lesser_than" },
  "lesserthanorequal": { translationKey: "lesser_than_or_equal" },
};

export const MOST_USED_VALUES_LIMIT = 5;

// Draggable components type
export const DragTypes = keyMirror({
  FORM_ITEM: null,
  FORM_ITEM_OPTION: null,
  GUEST_FIELDS: null,
  GUEST_TABLE_COLUMNS: null,
  GUEST_PRODUCT_COLLECTION_FIELDS: null,
  VALUE_LIST: null,
  WEBSITE_SECTIONS: null,
  SECTION_BLOCKS: null,
  TICKETS: null,
  FORM_STEP: null,
  STREAMING_ARCHIVE: null,
  PROGRAM_FILTERS: null,
  PROGRAM_OPTIONS: null,
  LEAD_QUALIFICATION_FIELD_POSSIBLE_VALUES: null,
  LEAD_QUALIFICATION_FIELDS: null,
  GUEST_PRODUCT_COLLECTION_FIELD_POSSIBLE_VALUES: null,
});

export const NbSavedSearchesLimit = 3;

export const COLUMN_STANDARD_FIELD_TYPE = "standard_guest_fields";
export const COLUMN_GUEST_FIELD_TYPE = "guest_fields";
export const COLUMN_ACCESSPOINT_TYPE = "accesspoints";
export const COLUMN_LINK_TYPE = "link";
export const COLUMN_QUARTILE_THEMATIC_TYPE = "quartile_thematics";
export const COLUMN_STANDARD_SCORE_THEMATIC_TYPE = "std_thematics";

export const COLUMN_SECTION_STANDARD_TYPE = {
  name: COLUMN_STANDARD_FIELD_TYPE,
  title: I18n.t("react.export_columns_filter.standard_fields"),
  itemsKeyInProps: "standardFields",
  selectedItemsKeyInState: "selectedStandardFields",
  lastSelectedItemKeyInState: "lastSelectedStandardField",
  itemIdKey: "key",
  itemNameKey: "label"
};

export const COLUMN_SECTION_GUEST_FIELD_TYPE = {
  name: COLUMN_GUEST_FIELD_TYPE,
  title: I18n.t("react.export_columns_filter.custom_fields"),
  itemsKeyInProps: "guestFields",
  selectedItemsKeyInState: "selectedGuestFields",
  lastSelectedItemKeyInState: "lastSelectedGuestField",
  itemIdKey: "key",
  itemNameKey: "key"
};

export const COLUMN_SECTION_ACCESSPOINT_TYPE = {
  name: COLUMN_ACCESSPOINT_TYPE,
  title: I18n.t("react.export_columns_filter.accreditations"),
  itemsKeyInProps: "accesspoints",
  selectedItemsKeyInState: "selectedAccesspoint",
  lastSelectedItemKeyInState: "lastSelectedAccesspoint",
  itemIdKey: "id",
  itemNameKey: "name"
};

export const COLUMN_SECTION_LINK_TYPE = {
  name: COLUMN_LINK_TYPE,
  title: I18n.t("react.reports.guests_table_columns_picker.links"),
  itemsKeyInProps: "links",
  selectedItemsKeyInState: "selectedLinks",
  lastSelectedItemKeyInState: "lastSelectedLink",
  itemIdKey: "identifier",
  itemNameKey: "name"
};

export const COLUMN_SECTION_QUARTILES_THEMATIC_TYPE = {
  name: COLUMN_QUARTILE_THEMATIC_TYPE,
  title: I18n.t("react.reports.guests_table_columns_picker.quartile_thematics"),
  itemsKeyInProps: "quartile_thematics",
  selectedItemsKeyInState: "selectedQuartileThematics",
  lastSelectedItemKeyInState: "lastSelectedQuartileThematic",
  itemIdKey: "identifier",
  itemNameKey: "name",
  itemsAlreadySorted: true
};

export const COLUMN_SECTION_STANDARD_SCORE_THEMATIC_TYPE = {
  name: COLUMN_STANDARD_SCORE_THEMATIC_TYPE,
  title: I18n.t("react.reports.guests_table_columns_picker.std_thematics"),
  itemsKeyInProps: "std_thematics",
  selectedItemsKeyInState: "selectedStdThematics",
  lastSelectedItemKeyInState: "lastSelectedStdThematic",
  itemIdKey: "identifier",
  itemNameKey: "name",
  itemsAlreadySorted: true
};

export const NetworkingMeetingType = {
  VIRTUAL: "virtual",
  PHYSICAL: "physical",
  PHYSICAL_OR_VIRTUAL: "physical_or_virtual"
};

// Must be the same as InvitationModeration::INVITATION_MODERATION_STATUSES
export const INVITATION_MODERATION_STATUSES = [
  "pending",
  "accepted",
  "rejected",
];

// extra standard_guest_fields
export const IDENTITY_CELL = "x-vx-identity";
export const CATEGORY_CELL = "x-vx-category";
export const EMAIL_CELL = "x-vx-mail";
export const BADGES_CELL = "x-vx-badges";
export const PRINT_BADGES_CELL = "x-vx-print-badges";
export const PAYMENT_CELL = "x-vx-payment";
export const REGISTRATION_FORM_CELL = "x-vx-form";
export const CONTACT_MODAL_CELL = "x-vx-contact-modal";

export const StandardLinkColumns = {
  [EMAIL_CELL]: { requiredFeatures: [CONFIRMATION_EMAIL] },
  [BADGES_CELL]: { requiredFeatures: [BADGES] },
  [PRINT_BADGES_CELL]: { requiredFeatures: [BADGES] },
  [REGISTRATION_FORM_CELL]: { requiredFeatures: [REGISTRATION_FORM] }
};
export const EngagementColumns = ["engagement_std_score", "engagement_quartile"];
export const StandardGuestFieldsInColumns = { ...{
  [IDENTITY_CELL]: null,
  [CATEGORY_CELL]: null,
  [PAYMENT_CELL]: null,
  [CONTACT_MODAL_CELL]: null,
  ["uid"]: null,
  ["created_at"]: null,
  ["registered_at"]: null,
  ["updated_at"]: null,
  ["email"]: null,
  ["first_name"]: null,
  ["last_name"]: null,
  ["company_name"]: null,
  ["company_id_number"]: null,
  ["position"]: null,
  ["phone_number"]: null,
  ["phone_number_prefix"]: null,
  ["address"]: null,
  ["country"]: null,
  ["country_name"]: null,
  ["vat_number"]: { requiredFeatures: [PAYMENT] },
  ["message"]: null,
  ["person_parent_uid"]: null,
  ["payment_promo_code"]: null,
  ["order_uid"]: null,
  ["utm_source"]: null,
  ["utm_medium"]: null,
  ["utm_campaign"]: null,
  ["status"]: null,
  ["showed_up"]: null,
  ["locale"]: { requiredFeatures: [MULTILINGUAL] },
  ["rank"]: null,
  ["event_campaign_optin"]: null,
  ["account_campaign_optin"]: null,
  ["invitations_quota"]: { requiredFeatures: [EXHIBITOR_INVITATIONS] },
  ["products_quota"]: null,
  ["invitations_quota_mapping"]: { requiredFeatures: [EXHIBITOR_INVITATIONS] },
  ["collections_quota"]: { requiredFeatures: [EXHIBITOR_INVITATIONS] },
  ["email_cc"]: null,
  ["seat_number"]: { requiredFeatures: [SEATSIO] },
  ["main_exhibitor_affiliation_code"]: null,
  ["exhibitor_affiliation_code"]: null,
  ["invitation_moderation_status"]: null,
  ["third_party_applications_refs"]: null,
}, ...EngagementColumns.reduce((acc, engagementColumn) => {
  acc[engagementColumn] = { requiredFeatures: [ENGAGEMENT_SCORING] };
  return acc;
}, {}) };
export const SuggestedGuestFieldsRegardlessType = ["country"];
export const CheckboxGuestFields = ["event_campaign_optin", "account_campaign_optin"];
export const BooleanGuestFields = ["event_campaign_optin", "account_campaign_optin"];
export const BulkEditableStandardsFields = ["address", "company_name", "country", "email_cc", "first_name", "last_name", "locale", "payment_promo_code", "phone_number", "phone_number_prefix", "position", "utm_campaign", "utm_medium", "utm_source", "vat_number", "event_campaign_optin", "account_campaign_optin", "invitation_moderation_status"];
export const DEFAULT_COLUMNS_SETTING = [
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: IDENTITY_CELL
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: CATEGORY_CELL
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: "email"
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: "status"
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: "company_name"
  },
  {
    type: COLUMN_LINK_TYPE,
    identifier: BADGES_CELL
  },
  {
    type: COLUMN_LINK_TYPE,
    identifier: EMAIL_CELL
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: PAYMENT_CELL
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: "registered_at"
  },
  {
    type: COLUMN_STANDARD_FIELD_TYPE,
    identifier: "created_at"
  }
];

// Regex
export const EMAIL_REGEX = /^(?:[^@\s]+)@(?:(?:[-a-z0-9]+\.)+[a-z]{2,})$/;
export const FORM_PAGE_REGEX = /^registration\/([a-f0-9]{24})$/;
export const CONFIRMATION_PAGE_REGEX = /^registration\/([a-f0-9]{24})\/confirmation$/;

export const RESOURCE_LIST_SECTIONS = ["guests-list", "program", "sessions-list", "guest-products-list", "blog-articles-list"];

// should be equivalent of GuestConstants::STATIC_GUEST_FIELDS (different order)
export const StaticGuestFieldToExport = ["uid", "created_at", "updated_at", "registered_at", "guest_category", "email", "first_name", "last_name", "company_name", "company_id_number", "position", "phone_number", "phone_number_prefix", "address", "country", "country_name", "vat_number", "message", "person_parent_uid", "payment_promo_code", "payment_promo_code_label", "order_uid", "utm_source", "utm_medium", "utm_campaign", "badge_permalink", "browser_on_create", "status", "showed_up", "payment_total_incl_taxes", "payment_total_excl_taxes", "tax", "payment_status", "payment_balance", "payment_merchant_type", "alias_uids", "locale", "rank", "avatar", "thematics", "event_campaign_optin", "account_campaign_optin", "invitations_quota", "products_quota", "invitations_quota_mapping", "collections_quota", "email_cc", "seat_number", "main_exhibitor_affiliation_code", "exhibitor_affiliation_code", "website_path_slug", "vip_notification_on_checkin", "invitation_moderation_status", "third_party_applications_refs"];

// should be equivalent of GuestConstants::BASIC_IMPORT_GUEST_FIELDS (different order)
export const StaticGuestFieldsImport = ["uid", "email", "first_name", "last_name", "company_name", "company_id_number", "position", "rank", "phone_number", "phone_number_prefix", "country", "country_name", "vat_number", "message", "payment_promo_code", "vip_checkin_notification_sms_phone_numbers", "payment_merchant_type", "address", "locale", "avatar", "thematics", "event_campaign_optin", "account_campaign_optin", "invitations_quota", "products_quota", "email_cc", "seat_number", "new_person_parent_uid", "website_path_slug", "vip_notification_on_checkin", "invitation_moderation_status", "salesforce_contact_id", "salesforce_lead_id", "salesforce_campaign_member_id"];
export const StaticGuestFieldsForTicketing = ["email", "first_name", "last_name", "company_name", "position", "phone_number", "address", "country", "vat_number", "payment_promo_code"];

export const PRIMARY_COLOR = "#009688";
export const COLORS = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", PRIMARY_COLOR, "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000", "#4A4A4A", "#9B9B9B", "#FFFFFF"];
export const EVENT_DEFAULT_PRIMARY_COLOR = "#162950";
export const EVENT_DEFAULT_SECONDARY_COLOR = "#333333";
export const DEFAULT_THEMATICS_COLOR = "#777777";
export const DEFAULT_THEMATICS_COLOR_TEXT = "FFFFFF";
export const SESSION_TYPE_COLOR = "#EEEEEE";
export const SESSION_TYPE_COLOR_TEXT = "#333333";

// Action key that carries API call info interpreted by the API middleware
export const CALL_API = Symbol("Call API");

export const OptionsForCheckinPointGroupAdvancedMode = ["filter", "override_label", "order"];
export const OptionsForAccommodationAdvancedMode = ["filter"];

export const GuestCampaignStatusLocked = ["sent", "sending"];
export const GuestCampaignSegmentInformationKeys = ["list_no_email", "list_no_sms", "list_no_optin"];

export const DefaultUneditableFields = ["last_name", "first_name", "email", "company_name"];

export const exportSortOptions = [
  "last_name",
  "company_name",
  "uid",
  "other"
];

export const EtCaetera = "...";

export const EventDropFields = [
  "address",
  "currency",
  "description",
  "end_date",
  "organizer",
  "photo",
  "photo_original",
  "start_date",
  "timezone",
  "title"
];

export const orderedFeatures = [
  "guests_quota",
  "marketing_emails_pack_included",
  "campaign",
  "website",
  "registration",
  "qrcode",
  "badge",
  "guest_management",
  "checkin",
  "stats"
];

export const companyPrefilledGuestFields = [
  "company_activity",
  "company_activity_code",
  "company_address",
  "company_address_line_1",
  "company_address_line_2",
  "company_address_line_3",
  "company_city",
  "company_country",
  "company_country_code",
  "company_email",
  "company_facebook",
  "company_founded_at",
  "company_geolocation",
  "company_legal_form",
  "company_linkedin",
  "company_logo_url",
  "company_nic",
  "company_phone",
  "company_presentation",
  "company_profit",
  "company_revenue",
  "company_siren",
  "company_siret",
  "company_staff",
  "company_twitter",
  "vat_number",
  "company_wikipedia",
  "company_website",
  "company_zipcode"
];

export const TranslationsParentTypes = {
  "registration_forms": { requiredFeatures: [REGISTRATION_FORM] },
  "accesspoints": {},
  "guest_categories": {},
  "guest_fields": {},
  "email_templates": {},
  "custom_emails": {},
  "guest_campaigns": {},
  "website_layouts": {},
  "website_pages": {},
  "website_menus": {},
  "thematics": {},
  "leads_qualification": {},
  "guest_product_collections": {},
  "website": {},
  "event": {},
  "blog_articles": { requiredFeatures: [BLOG] },
  "session_rooms": {},
  "session_types": {},
  "programs": { requiredFeatures: [SCHEDULE] },
  "meeting_locations": { requiredFeatures: [SCHEDULED_1TO1_MEETING, EXHIBITOR_MEETINGS] },
  "document_templates": { requiredFeatures: [DOCUMENTS] }
};

export const addressPrefilledGuestFields = [
  "street_number",
  "route",
  "postal_code",
  "locality",
  "department",
  "region",
  "country",
  "country_alpha2",
  "address_google_maps_url"
];

export const nextFormStepBehaviour = [
  "default",
  "custom",
  "confirmation_page"
];

export const GuestCategoryFeatures = [
  {
    key: "invitation-email",
    name: I18n.t("react.guest_category.features.invitation_email"),
    description: I18n.t("react.guest_category.features.invitation_email_desc"),
    field: "invitation_email_enabled",
    emailType: "invitation",
    icon: "fa-regular fa-envelope",
    alwaysDisplayed: (category) => category.use_new_exhibitor_invitation_system && category.invitations_enabled,
    displayedIf: (category) => category.use_new_exhibitor_invitation_system && category.invitations_enabled,
    requiredFeatures: [EXHIBITOR_INVITATIONS]
  },
  {
    key: "registration-form",
    name: I18n.t("react.guest_category.features.registration_form"),
    field: "custom_registration_form_enabled",
    icon: "fa-regular fa-list-alt",
    alwaysDisplayed: () => true,
    pathMethod: "pathToGuestCategoryLiquidField",
    requiredFeatures: [REGISTRATION_FORM]
  },
  {
    key: "payment",
    name: I18n.t("react.guest_category.features.payment"),
    description: I18n.t("react.guest_category.features.payment_desc"),
    field: "payment_enabled",
    icon: "fa-regular fa-credit-card",
    pathMethod: "pathToGuestCategoryPayment",
    requiredFeatures: [PAYMENT]
  },
  {
    key: "moderation",
    name: I18n.t("react.guest_category.features.moderation"),
    description: I18n.t("react.guest_category.features.moderation_desc"),
    field: "moderated_registrations_enabled",
    icon: "fa-regular fa-lock",
    pathMethod: "pathToGuestCategoryModeration",
    requiredFeatures: [MODERATION]
  },
  {
    key: "promo-code",
    name: I18n.t("react.guest_category.features.promo_code"),
    description: I18n.t("react.guest_category.features.promo_code_desc"),
    field: "promo_code_required",
    icon: "fa-regular fa-badge-percent",
    pathMethod: "pathToGuestCategoryEdit",
    requiredFeatures: [PROMO_CODES]
  },
  {
    key: "invoice",
    name: I18n.t("react.guest_category.features.invoice"),
    description: I18n.t("react.guest_category.features.invoice_desc"),
    field: "zoho_enabled",
    dependencies: ["payment_enabled"],
    pathMethod: "pathToGuestCategoryZohoConfiguration",
    requiredFeatures: [INVOICING]
  },
  {
    key: "badge",
    name: I18n.t("react.guest_category.features.badge"),
    description: I18n.t("react.guest_category.features.badge_desc"),
    field: "badge_enabled",
    icon: "fa-regular fa-qrcode",
    pathMethod: "pathToBadgeConfiguration",
    requiredFeatures: [BADGES]
  },
  {
    key: "wallet_pass",
    name: I18n.t("react.guest_category.features.wallet_pass"),
    description: I18n.t("react.guest_category.features.wallet_desc"),
    field: "wallet_pass_enabled",
    icon: "fa-regular fa-wallet",
    pathMethod: "pathToWalletPassConfiguration",
    requiredFeatures: [WALLET_PASS]
  },
  {
    key: "confirmation-email",
    name: I18n.t("react.guest_category.features.confirmation_email"),
    field: "confirmation_email_enabled",
    emailType: "confirmation",
    icon: "fa-regular fa-envelope",
    alwaysDisplayed: () => true,
    pathMethod: "pathToConfirmationEmailConfiguration",
    requiredFeatures: [CONFIRMATION_EMAIL]
  },
  {
    key: "moderation-email",
    name: I18n.t("react.guest_category.features.moderation_email"),
    description: I18n.t("react.guest_category.features.moderation_email_desc"),
    field: "moderation_email_enabled",
    emailType: "moderation",
    icon: "fa-regular fa-envelope",
    dependencies: ["moderated_registrations_enabled"],
    pathMethod: "pathToModerationEmailConfiguration"
  },
  {
    key: "edition-link-email",
    name: I18n.t("react.guest_category.features.edition_link_email"),
    description: I18n.t("react.guest_category.features.edition_link_email_desc"),
    field: "edition_link_email_enabled",
    emailType: "edition_link",
    icon: "fa-regular fa-envelope",
    adminOnly: true,
    pathMethod: "pathToEditionLinkEmailConfiguration"
  },
  {
    key: "documents",
    name: I18n.t("react.guest_category.features.documents"),
    description: I18n.t("react.guest_category.features.documents_desc"),
    field: "documents_enabled",
    icon: "fa-regular fa-file",
    pathMethod: "pathToGuestCategoryDocuments",
    requiredFeatures: [DOCUMENTS]
  }
];

export const GuestCategoryFeaturesBeforeRegistrationGroup = ["invitation-email"];
export const GuestCategoryFeaturesRegistrationGroup = ["registration-form"];
export const GuestCategoryFeaturesPaymentGroup = ["payment", "moderation", "promo-code"];
export const GuestCategoryFeaturesInvoiceGroup = ["invoice"];
export const GuestCategoryFeaturesPreRegistrationGroup = GuestCategoryFeaturesRegistrationGroup.concat(GuestCategoryFeaturesPaymentGroup).concat(GuestCategoryFeaturesInvoiceGroup);

export const GuestCategoryFeaturesPostRegistrationGroup = ["wallet_pass", "badge", "confirmation-email", "moderation-email", "edition-link-email", "documents"];
export const CUSTOM_ERROR_SYNCHRONIZE_CONTACT_FIELD_INTO_EVENT = "synchronize_contact_fields_into_event";
export const CUSTOM_ERROR_SYNCHRONIZE_CONTACT_INTO_EVENT = "synchronize_contacts_into_event";

export const ONLINE_COLOR = "#65b343";
export const OFFLINE_COLOR = "#ff5252";
export const ENGAGEMENT_ACTIONS = [
  "register_session",
  "unregister_session",
  "checkin_session",
  "uncheckin_session",
  "page_visit",
  "lead_creation",
  "lead_deletion",
  "register_thematic",
  "unregister_thematic",
  "open_email",
  "click_email",
  "register",
  "add_guest_product_bookmark",
  "remove_guest_product_bookmark"
];

export const STANDARD_THEME_NAME = "standard";
export const PREMIUM_THEME_NAME = "grand-conference";
export const DELUXE_THEME_NAME = "convention-usf";
export const THEME_NAMES = {
  standard: STANDARD_THEME_NAME,
  premium: PREMIUM_THEME_NAME,
  deluxe: DELUXE_THEME_NAME
};

export const URLS_DOC_STARTER_KIT_FEATURES = {
  has_website: "https://help.eventmaker.io/v/fr/nos-fonctionnalites/site-web/cms",
  has_visitor_space: "https://help.eventmaker.io/v/fr/nos-fonctionnalites/site-web/espace-personnel",
  has_exhibitor_space: "https://help.eventmaker.io/v/fr/conseils-et-bonnes-pratiques/gerer-ses-exposants-avec-eventmaker#espace-exposant",
  has_program: "https://help.eventmaker.io/configurer-mon-evenement/publier-mon-programme"
};

export const UNLIMITED = "unlimited";
export const LIMITED = "limited";

export const PATH_NAME_RESOURCE_ID_REGEX = /([a-f0-9]{24})$/;

// similar to DefaultAvatarService::DEFAULT_AVATAR_API_BASE_URL
export const FALLBACK_AVATAR_URL = "https://mobicheckin-assets.s3-eu-west-1.amazonaws.com/blank-avatar.svg";

export const UI_COLORS = {
  brand50: "#EAFDF9",
  brand100: "#C7F7EE",
  brand500: "#20A599",
  brand600: "#19867F",
  brand700: "#187772",
  grey40: "#FBFCFD",
  grey50: "#F8FAFC",
  grey100: "#F1F5F9",
  grey200: "#E2E8F0",
  grey300: "#CBD5E1",
  grey400: "#94A3B8",
  grey500: "#64748B",
  grey600: "#475569",
  grey700: "#334155",
  grey900: "#0F172A"
};

export const DASHBOARD_HEADER_NO_VALUE_STR = "-";

// Should have the same value as END_OF_STREAM into TextGenerator.rb
export const END_OF_STREAM = "___END_OF_STREAM___";

export const CART_ITEMS_STATS_HEADERS = [
  "guest",
  "date",
  "order_id",
  "transaction_id",
  "card_brand",
  "item",
  "price_excl_vat",
  "vat",
  "price_incl_taxes",
  "discount_amount",
  "refund_amount",
  "quantity",
  "total_excl_taxes",
  "total_incl_taxes"
];

export const GUEST_PRODUCT_INFORMATION_REQUEST_STATUS_COLORS = {
  pending: {
    color: "#78350F",
    background: "#FEF3C7",
  },
  accepted: {
    color: "#0F4A48",
    background: "#C7F7EE"
  },
  declined: {
    color: "#7F1D1D",
    background: "#FEE2E2"
  },
};

export const WORKFLOW_STEP_TRIGGER = "trigger";
export const WORKFLOW_STEP_ACTION = "action";
export const WORKFLOW_STEP_FILTER = "filter";
export const WORKFLOW_TRIGGER_SELECTION = "trigger_selection";
export const WORKFLOW_TRIGGER_EDITION = "trigger_edition";
export const WORKFLOW_ACTION_SELECTION = "action_selection";
export const WORKFLOW_ACTION_EDITION = "action_edition";
export const WORKFLOW_FILTER_EDITION = "filter_edition";

export const AVAILABLE_OPERATORS_FOR_AUTOMATION = {
  "text_equals": { translationKey: "text_equals_to" },
  "text_nequals": { translationKey: "text_not_equals_to" },
  "text_contains": { translationKey: "text_contains" },
  "text_doesnotcontain": { translationKey: "text_does_not_contain" },
  "text_startswith": { translationKey: "text_starts_with" },
  "text_doesnotstartwith": { translationKey: "text_does_not_start_with" },
  "text_endswith": { translationKey: "text_ends_with" },
  "text_doesnotendwith": { translationKey: "text_does_not_end_with" },
  "number_equals": { translationKey: "number_equals_to" },
  "number_nequals": { translationKey: "number_not_equals_to" },
  "number_greaterthan": { translationKey: "number_greater_than" },
  "number_lesserthan": { translationKey: "number_lesser_than" },
  "number_greaterthanorequal": { translationKey: "number_greater_than_or_equal" },
  "number_lesserthanorequal": { translationKey: "number_lesser_than_or_equal" },
  "boolean_true": { translationKey: "boolean_true" },
  "boolean_false": { translationKey: "boolean_false" },
  "misc_exists": { translationKey: "misc_exists" },
  "misc_doesnotexist": { translationKey: "misc_does_not_exist" },
};

export const WORKFLOW_RUNS_PER_PAGE = 20;
export const WORKFLOW_RUN_STATUSES = {
  "todo": { backgroundClass: "bg-secondary", iconClass: "clock" },
  "not_triggered": { backgroundClass: "bg-secondary", iconClass: "filter" },
  "in_progress": { backgroundClass: "bg-warning", iconClass: "clock" },
  "failed": { backgroundClass: "bg-danger", iconClass: "remove" },
  "finished": { backgroundClass: "bg-success", iconClass: "check" },
  "filtered": { backgroundClass: "bg-secondary", iconClass: "filter" }
};

export const WORKFLOW_STEP_RUN_STATUSES = {
  "todo": { backgroundClass: "bg-secondary", iconClass: "clock" },
  "in_progress": { backgroundClass: "bg-warning", iconClass: "clock" },
  "failed": { backgroundClass: "bg-danger", iconClass: "remove" },
  "succeeded": { backgroundClass: "bg-success", iconClass: "check" },
  "filtered": { backgroundClass: "bg-secondary", iconClass: "filter" }
};

export const WORKFLOW_TRIGGER_GROUPS = {
  "guest": {
    "icon": "fa-regular fa-user",
    "triggers": [
      "guest_did_create",
      "guest_did_update",
      "guest_did_register",
      "guest_did_destroy",
      "guest_invitation_did_create",
      "guest_landed_in_moderation",
      "guest_registers_to_checkin_point",
      "guest_registers_to_session",
      "guest_showed_up_changed"
    ],
  },
  "access_control": {
    "icon": "fa-regular fa-scanner-gun",
    "triggers": [
      "access_control_did_create",
      "access_control_did_destroy"
    ]
  },
  "meeting": {
    "icon": "fa-regular fa-scanner-gun",
    "triggers": [
      "meeting_booked",
      "meeting_confirmed"
    ]
  }
};

export const WORKFLOW_ACTION_STRATEGIES = [
  "change_guest_category",
  "cancel_pending_meeting_request",
  "confirm_pending_meeting_request",
  "change_guest_status",
  "send_email",
  "send_exhibitor_invitation",
  "add_label",
  "remove_label",
  "delay",
];

const LOCKED_WORKFLOW_ACTION_STRATEGIES = [
  "sync_into_salesforce"
];

export const WORKFLOW_STRATEGIES = Object.values(WORKFLOW_TRIGGER_GROUPS)
  .flatMap((config) => config.triggers)
  .concat(WORKFLOW_ACTION_STRATEGIES + LOCKED_WORKFLOW_ACTION_STRATEGIES);

export const SALESFORCE_FIELDS = [
  "contact_id",
  "lead_id",
  "campaign_member_id",
  "last_synced_at",
  "error"
];

export const CAMPAIGN_FILTER_TYPE_ITEMS = {
  all: { id: "all", name: I18n.t("react.guest_campaigns.type_filter.all") },
  email: { id: "email", name: I18n.t("react.guest_campaigns.type_filter.email"), icon: "fa-regular fa-fw fa-envelope" },
  sms: { id: "sms", name: I18n.t("react.guest_campaigns.type_filter.sms"), icon: "fa-regular fa-fw fa-mobile" },
  message: { id: "message", name: I18n.t("react.guest_campaigns.type_filter.message"), icon: "fa-regular fa-fw fa-bell" }
};
